import * as React from "react";
import { useState, useEffect } from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";
import SEO from "../components/Seo";
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`;
    setCurrentDate(formattedDate);
  }, []);
  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="container">
            <div className="with_sidebar">
              <div className="row">
                <div className="col-sm-8 wsb_left">
                  <div className="content">
                    <h2>Privacy Policy</h2>
                    <p>Last Updated: {currentDate}</p>
                    <p>
                      Welcome to Answering Real Estate ("we," "our," or "us").
                      This Privacy Policy explains how we collect, use,
                      disclose, and safeguard your information when you visit
                      our website,{" "}
                      <a href="/" target="_blanck">
                        answeringrealestate.com{" "}
                      </a>
                      (the "Site"). Please read this Privacy Policy carefully.
                      If you do not agree with the terms of this Privacy Policy,
                      please do not access the Site.
                    </p>

                    <h3>1. Information We Collect</h3>
                    <p>
                      We may collect information about you in a variety of ways.
                      The information we may collect on the Site includes:
                    </p>

                    <p>
                      <b>Personal Data</b> Personally identifiable information,
                      such as your name, shipping address, email address, and
                      telephone number, and demographic information, such as
                      your age, gender, hometown, and interests, that you
                      voluntarily give to us when you register with the Site or
                      when you choose to participate in various activities
                      related to the Site, such as online chat and message
                      boards.
                    </p>

                    <p>
                      <b>Derivative Data</b> Information our servers
                      automatically collect when you access the Site, such as
                      your IP address, your browser type, your operating system,
                      your access times, and the pages you have viewed directly
                      before and after accessing the Site.
                    </p>

                    <p>
                      <b>Financial Data</b> Financial information, such as data
                      related to your payment method (e.g., valid credit card
                      number, card brand, expiration date) that we may collect
                      when you purchase, order, return, exchange, or request
                      information about our services from the Site.
                    </p>

                    <p>
                      <b>Mobile Device Data</b> Device information, such as your
                      mobile device ID, model, and manufacturer, and information
                      about the location of your device, if you access the Site
                      from a mobile device.
                    </p>

                    <h3>2. Use of Your Information</h3>
                    <p>
                      Having accurate information about you permits us to
                      provide you with a smooth, efficient, and customized
                      experience. Specifically, we may use information collected
                      about you via the Site to:
                    </p>
                    <ul style={{ listStyle: "disc" }}>
                      <li>Create and manage your account.</li>
                      <li>Process your transactions.</li>
                      <li>Manage your orders.</li>
                      <li>Deliver services to you.</li>
                      <li>Respond to customer service requests.</li>
                      <li>Send you administrative information.</li>
                      <li>
                        Offer new products, services, and/or recommendations to
                        you.
                      </li>
                      <li>
                        Prevent fraudulent transactions, monitor against theft,
                        and protect against criminal activity.
                      </li>
                      <li>Process payments and refunds.</li>
                      <li>
                        Request feedback and contact you about your use of the
                        Site.
                      </li>
                      <li>Resolve disputes and troubleshoot problems.</li>
                      <li>Send you a newsletter.</li>
                      <li>Perform other business activities as needed.</li>
                    </ul>

                    <h3>3. Disclosure of Your Information</h3>
                    <p>
                      We may share information we have collected about you in
                      certain situations. Your information may be disclosed as
                      follows:
                    </p>

                    <p>
                      <b>By Law or to Protect Rights</b> If we believe the
                      release of information about you is necessary to respond
                      to legal process, to investigate or remedy potential
                      violations of our policies, or to protect the rights,
                      property, and safety of others, we may share your
                      information as permitted or required by any applicable
                      law, rule, or regulation.
                    </p>

                    <p>
                      <b>Business Transfers</b> We may share or transfer your
                      information in connection with, or during negotiations of,
                      any merger, sale of company assets, financing, or
                      acquisition of all or a portion of our business to another
                      company.
                    </p>

                    <p>
                      <b>Interactions with Other Users</b> If you interact with
                      other users of the Site, those users may see your name,
                      profile photo, and descriptions of your activity.
                    </p>

                    <p>
                      <b>Third-Party Service Providers</b>We may share your
                      information with third parties that perform services for
                      us or on our behalf, including payment processing, data
                      analysis, email delivery, hosting services, customer
                      service, and marketing assistance.
                    </p>

                    <h3>4. Security of Your Information</h3>
                    <p>
                      We use administrative, technical, and physical security
                      measures to help protect your personal information. While
                      we have taken reasonable steps to secure the personal
                      information you provide to us, please be aware that
                      despite our efforts, no security measures are perfect or
                      impenetrable, and no method of data transmission can be
                      guaranteed against any interception or other type of
                      misuse.
                    </p>

                    <h3>5. Policy for Children</h3>
                    <p>
                      We do not knowingly solicit information from or market to
                      children under the age of 13. If we learn we have
                      collected personal information from a child under age 13
                      without verification of parental consent, we will delete
                      that information as quickly as possible.
                    </p>

                    <h3>6. Changes to This Privacy Policy</h3>
                    <p>
                      We may update this Privacy Policy from time to time in
                      order to reflect, for example, changes to our practices or
                      for other operational, legal, or regulatory reasons. Any
                      changes will be posted on this page with an updated
                      revision date.
                    </p>

                    <h3>7. Contact Us</h3>
                    <p>
                      If you have questions or comments about this Privacy
                      Policy, please contact us at:
                    </p>
                    <p>Answering Real Estate</p>
                    <p>Email: support@answeringrealestate.com</p>
                    <p>Address: 110 Lake Ave S Suite 32, Nesconset, NY 11767</p>
                  </div>
                </div>
                <div className="col-sm-4 wsb_right">
                  <aside className="sidebar">
                    <ServicesSidebar />
                    <RecentPosts />
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["PRIVACY_POLICY"]} />;
