import * as React from "react";
import "../styles/styles.css";

import logoImg from "../images/logo_white.png";
import { Link } from "gatsby";

import Offcanvas from "react-bootstrap/Offcanvas";

const HomeHeader = () => {
  const [isToggled, setToggle] = React.useState(false);

  return (
    <header className="header">
      <div className="header_top" style={{ border: "none" }}>
        <div className="container">
          <div className="topleft">
            <div className="logo">
              <a href="/">
                <img src={logoImg} alt="" title="" width="529" height="82" />
              </a>
            </div>
          </div>
          <div className="ht_right">
            <div className="nav_block">
              <div className="nav-menu">
                <ul className="clearfix">
                  <li>
                    <Link to="/services" className="home_header_navlink">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/tour">Tour</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/signup">Sign Up</Link>
                  </li>
                </ul>
              </div>
            </div>
            <span className="resnav" onClick={() => setToggle(!isToggled)}>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <Offcanvas
              show={isToggled}
              onHide={() => setToggle(!isToggled)}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="mobileMenu">
                  <ul>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/tour">Tour</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  </ul>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
