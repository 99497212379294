import * as React from "react";
import "../styles/styles.css";

import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="wf-container-footer">
        <div className="container">
          <div className="row">
            <div id="nav_menu-2" className="col-sm-3">
              <div className="foot-title">Explore</div>
              <div className="foot_menu">
                <ul>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/tour">Tour</a>
                  </li>
                  {/* <li>
                                        <a href="/blog">Blog</a>
                                    </li> */}
                  <li>
                    <a href="about">About</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/signup">Sign Up</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="nav_menu-4" className="col-sm-3">
              <div className="foot-title">Company</div>
              <div className="foot_menu">
                <ul>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  {/* <li>
                                        <a href="/blog">Blog</a>
                                    </li> */}
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>

            <div id="nav_menu-3" className="col-sm-3">
              <div className="foot-title">Services</div>
              <div className="foot_menu">
                <ul>
                  <li>
                    <Link to="/quality-answering-service">
                      Quality Answering Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/lead-capturing">Lead Capturing</Link>
                  </li>
                  <li>
                    <Link to="/intelligent-dispatching">
                      Intelligent Dispatching
                    </Link>
                  </li>
                  <li>
                    <Link to="/appointment-scheduling">
                      Appointment Scheduling
                    </Link>
                  </li>
                  <li>
                    <Link to="/bilingual-answering-service">
                      Bilingual Answering Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/real-estate-answering-service">
                      Real Estate Answering Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/247-live-answering-service">
                      24/7 Live Answering Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div id="custom_html-2" className="col-sm-3">
              <div className="foot-title">Contact</div>
              <div className="footer_con">
                <ul>
                  <li>
                    <a href="tel:6314501000">Main: 631.450.1000</a>
                  </li>

                  <li>
                    110 Lake Ave S Suite 32,
                    <br />
                    Nesconset, NY 11767
                  </li>
                  <li>
                    <a href="mailto:info@answeringrealestate.com">
                      Email us at info@answeringrealestate.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
