import React, { useState } from 'react'

const CustomAccordion = ({ title, faq}) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div>
            <div className="tour_toggle" onClick={() => setIsActive(!isActive)}>
                <span className="ticon">
                    {!isActive && <span className="plus">+</span>}
                    {isActive && <span className="minus">–</span>}
                </span>
                {title ?? ''}
            </div>
            {isActive && <div className="tour_toggle_content setting-up-acct">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="span_12_of_12">
                            {faq.filter((a, index) => index < 3).map(item => (
                                <>
                                    <h5 className="border-left-blue">{item.title}</h5>
                                    <p className="para-14">
                                        {item.content}
                                    </p>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="span_12_of_12 border-left">
                            {faq.filter((a, index) => index > 2).map(item => (
                                <>
                                    <h5 className="border-left-blue">{item.title}</h5>
                                    <p className="para-14">
                                        {item.content}
                                    </p>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default CustomAccordion