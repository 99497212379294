import * as React from "react";
import "../styles/styles.css";

import { Link } from "gatsby";

import postscribe from 'postscribe';
import MainLayout from "../components/MainLayout";
import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";

import { getPricing } from '../utils/embedScripts';

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

// markup
const IndexPage = () => {
  React.useEffect(() => {
    isBrowser && postscribe('#formPricing', getPricing);
  }, []);
  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="bread_heading_block">
            <div className="text-center pagehead">
              <h2>
                <strong>GET PRICING</strong>
              </h2>
            </div>
            <div className="breadcrumb_block">
              <ol className="breadcrumbs text-small">
                <li>
                  <Link to="/" title="">
                    <span>Home</span>
                  </Link>
                </li>
                <li className="current">
                  <span>Get Pricing</span>
                </li>
              </ol>
            </div>
          </div>
          <div className="container">
            <div className="with_sidebar">
              <div className="row">
                <div className="col-sm-8 wsb_left">
                  <div className="content">
                    <p>
                      Tell us about your business. We'll send you our pricing
                      information once you submit.
                    </p>
                    <div id="formPricing" className="form_box" style={{ height: '100vh' }} />
                    {/* <div id="formPricing" className="form_box" style={{ height: '100vh' }}>
                      <iframe src="https://answeringlegal.formstack.com/forms/are_pricing&embedded=true"
                        title="ARE Pricing" width="600" height="100%"></iframe>
                    </div> */}
                  </div>
                </div>
                <div className="col-sm-4 wsb_right">
                  <aside className="sidebar">
                    <ServicesSidebar />
                    <RecentPosts />
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO {...META_TAGS['PRICING']}/>
)
