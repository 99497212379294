import * as React from "react";
import { Link } from "gatsby";
import { InlineWidget } from "react-calendly";
import "../styles/styles.css";
import logoImg from "../images/logo.png";

import SEO from "../components/Seo";
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
  return (
    <div className="inner_main_wrapper" style={{ paddingTop: 0 }}>
      <div className="inner_container" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="content">
            <div className="top-logo">
              <div className="logo-middle">
                <Link to="/">
                  <img
                    src={logoImg}
                    alt="Answering Realstate logo"
                    title="Answering Realstate logo"
                  />
                </Link>
              </div>
            </div>
            <div className="signupbox">
              <h2 className="fsSectionHeading">
                Try Answering Real Estate For Free! Fill Out The Form Below For
                400 Free Minutes.
              </h2>
              {/* Calendly inline widget begin */}
              <div className="form_box text-center">
                <InlineWidget
                  styles={{ minWidth: "320px", height: "120vh" }}
                  url="https://calendly.com/answering-real-estate-inb/15-minute-discovery-call-discuss-our-services?hide_gdpr_banner=1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["SIGNUP"]} />;
