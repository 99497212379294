import * as React from "react";
import "../styles/styles.css";

import logoImg from "../images/logo.png";
import slideOne from "../images/slide1.jpg";
import customerSat from "../images/customer_sat.png";
import accurateMessage from "../images/accurate_message.png";
import business from "../images/business_247.png";
import specialized from "../images/specialized.png";
import dispatching from "../images/dispatching.png";
import conversation from "../images/conversation.png";
import training from "../images/training.png";
import leadCapturing from "../images/lead_capturing.png";
import calendar from "../images/calendar.png";
import check from "../images/check.png";
import Footer from "../components/Footer";
import HomeHeader from "../components/HomeHeader";
import Accordion from "../components/Accordion";
import SEO from "../components/Seo";
import { Link } from "gatsby";
import { META_TAGS } from "../utils/metaTags";

const FAQ_LIST = [
  {
    title: "Is Answering Real Estate 24/7?",
    content:
      "Yes, Answering Real Estate is open 24 hours a day, 7 days a week, 365 days a year. (366 on leap years.)",
  },
  {
    title: `Does Answering Real Estate require I sign a contract?`,
    content: `No. Answering Real Estate never requires a contract, and
    is an at-will service. If you wish to cancel at any time,
    you may do so.`,
  },
  {
    title: ` How is Answering Real Estate different from others?`,
    content: `Answering Real Estate specializes in answering for the
    Real Estate industry, and doesn’t piecemeal its services.
    You get every premium feature for the same flat rate every
    month. No other answering service can offer this type of
    deal.`,
  },
  {
    title: `Can I use Answering Real Estate for nights and weekends?`,
    content: ` Yes. If you have staff that can handle your phones during
    the day, you can just forward your calls to Answering Real
    Estate during the after hours and weekends for your
    business.`,
  },
  {
    title: ` Can Answering Real Estate patch important calls to my cell
    phone?`,
    content: `Yes. If you’ve requested certain types of calls to be
    patched into your mobile phone, we can easily announce and
    transfer calls to you, wherever you are.`,
  },
  {
    title: ` How is Answering Real Estate different from having a
    voicemail?`,
    content: `According to Forbes Magazine, 72% of callers hang up on
    voicemail and try to call another business for immediate
    help, rather than actually leave a message. Answering Real
    Estate provides you with a live representative of your
    business, which will not automatically turn away your
    callers and will instead capture you new business, even
    when you don’t answer the phone.`,
  },
  {
    title: `Can I try Answering Real Estate before I decide if I want
    to stay?`,
    content: ` Yes, Answering Real Estate offers a 400 minute free trial with
    no contracts, credit cards, or down payment required. You
    get to see if you like the service, while Answering Real
    Estate measures your call volume so that we can offer you
    a flat rate.`,
  },
  {
    title: ` How difficult is it to start a free trial with Answering
    Real Estate?`,
    content: `Not difficult at all. Simply give us a call at
    631-450-1000 and let us know you’re interested in trying
    out our free trial. Our Customer Success Experts will walk
    you through everything. Often times, we can have your
    service set up the very same day.`,
  },
];

// markup
const IndexPage = () => {
  return (
    <>
      <div className="res_overlay">
        <span className="close_menu"></span>
      </div>
      <div className="responsive_nav"></div>

      <HomeHeader />

      <div className="banner_section">
        <div className="home_banner_section">
          <div className="home_slider">
            <div className="home-banner">
              <div className="item">
                <div className="slide_box">
                  <img
                    src={slideOne}
                    alt=""
                    title=""
                    width="1600"
                    height="580"
                  />
                </div>
                <div className="slide_text_wrap">
                  <div className="container">
                    <div className="slide_text">
                      <div className="stext1">
                        A live voice will make your customers feel at home
                      </div>
                      <div className="stext2">
                        See how a 24/7 live answering service will keep your
                        customers <br /> happy and help you grow your business.
                      </div>
                      <div className="sbtns">
                        <Link to="/signup" className="slbtn">
                          Get started for free
                        </Link>
                        <Link to="/get-a-quote" className="slbtn bordered">
                          Get a quote
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_section">
        <section className="benefits_section">
          <div className="container">
            <div className="secintro">
              <h2>Benefits you’ll love us for</h2>
              <p className="para-15 span_5_of_12">
                Answering Real Estate includes every premium feature with every
                account. Here are some of the reasons you’ll love your new real
                estate answering service.
              </p>
            </div>
            <div className="bnf_group">
              <div className="row">
                <div className="col-sm-4 benefit">
                  <img src={customerSat} alt="" />
                  <h5>Superior customer service</h5>
                  <p className="para-14">
                    Make sure that your customers never become frustrated with
                    your voicemail, and always have a pleasant experience.
                  </p>
                </div>
                <div className="col-sm-4 benefit">
                  <img src={accurateMessage} alt="" />
                  <h5>Accurate message taking</h5>
                  <p className="para-14">
                    You won’t have to excuse yourself and field phone calls,
                    because your new callers’ information will appear in your
                    inbox waiting immediately.
                  </p>
                </div>
                <div className="col-sm-4 benefit">
                  <img src={business} alt="" />
                  <h5>Live receptionists 24.7</h5>
                  <p className="para-14">
                    A 24/7 real estate answering service will help you field all
                    of the calls you can’t get to and obtain the business of
                    these callers.
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-container">
              <Link to="/signup" className="btn-md">
                Try for free
              </Link>
            </div>
            <hr className="span_10_of_12" />
          </div>
        </section>

        <section className="features_section">
          <div className="container">
            <div className="text-center">
              <h2>More to love about us</h2>
            </div>
            <div className="feature_list">
              <div className="row">
                <div className="col-sm-4">
                  <Link to="/real-estate-answering-service">
                    <div className="feature-box blue">
                      <img src={specialized} alt="" />
                      <h5>Real Estate Specialized</h5>
                      <p>
                        By training our virtual receptionists to answer only for
                        the real estate industry, we can integrate with your
                        business more efficiently.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link to="/intelligent-dispatching">
                    <div className="feature-box green">
                      <img src={dispatching} alt="" />
                      <h5>Intelligent Dispatching</h5>
                      <p>
                        If your callers know who they need to speak to, your
                        virtual receptionist will transfer that call to the
                        correct person in your office.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link to="/bilingual-answering-service">
                    <div className="feature-box yellow">
                      <img src={conversation} alt="" />
                      <h5>Bilingual Receptionists</h5>
                      <p>
                        Your Spanish speaking callers will never again become
                        frustrated when trying to speak to someone in your
                        office.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4">
                  {/* <Link to=""> */}
                  <div className="feature-box yellow">
                    <img src={training} alt="" />
                    <h5>Intensive Agent Training</h5>
                    <p>
                      We train our virtual receptionists rigorously to be
                      polite, professional, accurate, and 100% reliable. All of
                      the time.
                    </p>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-sm-4">
                  <Link to="/lead-capturing">
                    <div className="feature-box blue">
                      <img src={leadCapturing} alt="" />
                      <h5>Lead Capturing</h5>
                      <p>
                        Our virtual receptionists are trained to capture all
                        incoming leads by asking any specified questions you
                        insist.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link to="/appointment-scheduling">
                    <div className="feature-box green">
                      <img src={calendar} alt="" />
                      <h5>Appointment Scheduling</h5>
                      <p>
                        We offer calendar integrations that intelligently
                        identify your availability, so that we can offer times
                        to meet with you.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="trust_section">
          <div className="container">
            <div className="secintro">
              <h2>We’ve got your back</h2>
              <p className="span_5_of_12 para-15">
                The most important thing to us at Answering Real Estate is the
                trust between us and our customers. Here’s what we guarantee
                every single customer.
              </p>
            </div>
            <div id="trust_box" className="trust_list">
              <div className="row">
                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>Guaranteed flat rate</h6>
                  </div>
                  <p>
                    Every one of our customers is guaranteed a flat rate price
                    for each month, with no surprise charges. This simply means
                    that you are charged the same set price every month.
                  </p>
                </div>

                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>Maintenance dispatching</h6>
                  </div>
                  <p>
                    No one wants to answer the phone after hours to deal with
                    customer related issues. Now, with our maintenance
                    dispatching, we can patch your clients to the appropriate
                    person.
                  </p>
                </div>

                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>Minute overage protection</h6>
                  </div>
                  <p>
                    Even if you use more than your allotted minutes in a given
                    month, you won’t be charged overage fees at all. Don’t worry
                    about more phone calls coming in, we’re here to help your
                    business grow.
                  </p>
                </div>

                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>24/7 support</h6>
                  </div>
                  <p>
                    Encountering a problem late at night or early morning won’t
                    mean you have to wait. Our 24 hour support team is available
                    to fix any problem you might have.
                  </p>
                </div>

                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>Custom call scripting</h6>
                  </div>
                  <p>
                    Your business is your brand, and the way you handle your
                    phone calls is and should be important to you! We understand
                    this, so we customize your call scripting to align with your
                    business.
                  </p>
                </div>

                <div className="col-sm-6 check">
                  <div className="chflex">
                    <span>
                      <img className="fadein" src={check} alt="" />
                    </span>
                    <h6>Custom call forwarding</h6>
                  </div>
                  <p>
                    Using sophisticated call forwarding, We can help you forward
                    your calls at certain times of the day. Give yourself time
                    to answer before forwarding or let us answer when you’re out
                    of the office.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq_section">
          <div className="container">
            <div className="faqsec">
              <div className="center fadein">
                <h2>Frequently asked questions</h2>
              </div>
              <div className="faq_list">
                <div className="group row">
                  {FAQ_LIST.map((faq) => (
                    <Accordion {...faq} />
                  ))}
                </div>
              </div>
              <hr className="span_10_of_12" />
            </div>
          </div>
        </section>

        <section className="cta_section">
          <div className="container">
            <div id="cta">
              <div className="text-center">
                <h2>Start your 400 minute free trial!</h2>
                <p className="para-15 fadein">
                  Take your business to the next level with our 24/7 Real Estate
                  Answering Service.
                </p>
                <Link to="/signup" className="btn-lg">
                  Try Answering Real Estate Free
                </Link>
                <p className="sub_txt">
                  Have questions? Our team is here to help. Call us at
                  631.450.1000
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />

      {/* <a className="scrollup" href="javascript:void(0);"></a> */}
    </>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["HOME"]} />;
