import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Quality Answering Service</h2>
                                        <p>
                                            There are many businesses that do not need a high quality answering service in order to cater to their customers for a myriad of reasons. The Real Estate industry does not belong in that group of businesses who can use low quality answering services. Everything about Real Estate is an effort to make the customer happy, because ultimately what you’re doing is finding a place where they will either live or where they will build their business! There is a great deal of trust that goes into real estate, which means you need a quality answering service that will portray the same message to your callers that you would portray in your office: politeness, professionalism, efficacy, and accuracy..
                                        </p>
                                        <h3>Your Quality Answering Service and Your Customers</h3>
                                        <p>
                                            Your business should implement the same level of quality that they want their clients to receive. If you want your clients to have an excellent experience with your business, that will mean that you have to create remarkable interactions at every turn, and most times, the first interaction is the most important. That is why it is paramount that you have a quality answering service: so that the first call to your business does not leave a poor taste in the mouths of your potential customers, and that you get off on the right foot in order to obtain the caller’s business.
                                        </p>
                                        <h3>What Are The Attributes of a Quality Answering Service?</h3>
                                        <p>
                                            Answering Real Estate’s 24 hour customer support means
                                            that you never have to wait until the next business day to
                                            make changes to your account or fix a problem that you’re
                                            having! Call us, email us, or chat with us, we’re
                                            available at all times to keep your account and your
                                            business running as smoothly as possible. Our 24 hour
                                            support is included with every account, so don’t worry
                                            that live support will cost you extra. We’re here to help,
                                            no matter what time of day you need us.
                                        </p>
                                        <ul style={{ listStyle: 'inside', lineHeight: '30px', paddingTop: '22px' }}>
                                            <li>Professionalism</li>
                                            <li>Politeness</li>
                                            <li>Accuracy</li>
                                            <li>Fast and Efficient</li>
                                            <li>Virtual Receptionists Sound Happy</li>
                                        </ul>
                                        <p>
                                            If your answering service doesn’t meet these attributes, switch to Answering Real Estate! Not only are we a quality answering service, but we specialize in answering for the real estate profession. You won’t be sorry.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-4 wsb_right">
                                    <aside className="sidebar">
                                        <ServicesSidebar />
                                        <RecentPosts />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['QUALITY_ANSWERING_SERVICE']} />
)
