import React, { useState } from 'react'

const Accordion = ({ title, content, className }) => {
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        window && window?.gtag("FAQ_toggle_test", "click", { send_to: "G-XEP3WK72G0", id: 1, name: 'Test' })
        window && window?.dataLayer?.push({ "FAQ_toggle": title })
        setIsActive(!isActive)
    }

    return (
        <div className={className ?? "col-sm-6"}>
            <p className="faq" onClick={() => handleToggle()}>
                {title ?? ''}
            </p>
            {isActive && <p className="faq_answer para-14">
                {content ?? ''}
            </p>}
        </div>
    )
}

export default Accordion