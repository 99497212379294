import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Lead Capturing</h2>
                                        <p>
                                            The lifeblood of your business is the new leads you generate through your marketing and advertising, as well as the referrals you receive. In order to remain open and sustain profitability, your business needs a regular influx of new leads, which usually means you will turn to marketing and advertising in order to obtain prospective clients. However, if you aren’t prepared for when those potential customers try to actually get in contact with you, you could not only be missing out on the opportunity to do new business, but wasting your advertising and marketing dollars and efforts.
                                        </p>
                                        <h3>Why Your Answering Service Needs to Provide Lead Capturing Services</h3>
                                        <p>
                                            In order for your answering service to help increase your bottom line, and not just increase your overhead, your answering service needs to help you capture new leads while you can’t answer the phone. By providing lead capturing services, your answering service will provide a value to your business that comes in the form of a higher conversion rate, thanks to the newfound ease of converting potential customers. By receiving accurate and detailed information about each and every caller, you can determine the urgency and importance of every call, which matters need your attention immediately, and how to help that customer once you get a chance to call him/her back. Here are just some of the examples of information you need to obtain from each caller:
                                        </p>
                                        <ol>
                                            <li>Professionalism</li>
                                            <li>Politeness</li>
                                            <li>Accuracy</li>
                                            <li>Fast and Efficient</li>
                                            <li>Virtual Receptionists Sound Happy</li>
                                        </ol>
                                        <p>
                                        You can, of course, request for fewer or more questions to be asked of your new callers, but it is important that your answering service is extremely accurate when recording these responses.
                                        </p>
                                       
                                        <h3>The Importance of Lead Capturing Accuracy</h3>
                                        <p>
                                            If there is a miscommunication between your answering service and you, there is a risk that when you call your potential customer back that you seem unprofessional by having incorrect information about that person. For example, if your messages says a caller’s name was Joe, but it was actually John, you might frustrate the caller and portray the image that you can’t handle simple matters. Answering Real Estate’s lead capturing services are highly accurate because we specialize in answering for real estate. All of our virtual receptionists are extremely accurate, and you never receive any misinformation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['LEAD_CAPTURING']} />
)

