import * as React from "react";
import "../styles/styles.css";

import customization from "../images/customization.png";
import specialized from "../images/specialized.png";
import conversation from "../images/conversation.png";
import training from "../images/training.png";
import leadCapturing from "../images/lead_capturing.png";
import leadCapture2 from "../images/lead_capture2.png";
import calendar from "../images/calendar.png";
import afterhours from "../images/afterhours.png";

import MainLayout from "../components/MainLayout";
import Accordion from "../components/Accordion";
import { Link } from "gatsby";
import SEO from "../components/Seo";
import { META_TAGS } from "../utils/metaTags";

const FAQ_LIST = [
  // {
  //   title: `How do I sign up with Answering Real Estate?`,
  //   content: `Easy! Simply fill out a sign up form here:{" "}
  //   <Link to="/signup">
  //     https://answeringrealestate.com/sign-up/
  //   </Link>{" "}
  //   or give us a call at 631-450-1000, and one of our
  //   customer success representatives will help you setup
  //   your account.`
  // },
  {
    title: `Only some of my clients speak Spanish, is that an
    issue?`,
    content: `No. Answering Real Estate is fully bilingual 24
    hours a day. Even if you don’t know when a Spanish
    caller is going to try and reach you, our staff is
    bilingual on the fly, meaning we can handle the call
    with ease, even when there is no indication of which
    language the caller speaks beforehand.`,
  },
  {
    title: `Can I change how the virtual receptionists answer?`,
    content: `Of course! You can change everything the virtual
    receptionists say from how they answer the phones to
    the questions they ask your callers.`,
  },
  {
    title: `Can I still answer my own calls if I use Answering
    Real Estate?`,
    content: `Yes! Using conditional call forwarding, you can
    customize the number of rings that your phone
    receives first, before the call automatically
    forwards over to us at Answering Real Estate. Don’t
    worry, we’ll set up the conditional call forwarding
    for you.`,
  },
  {
    title: `How do I receive my messages?`,
    content: `We send you your messages via SMS text, email, or a
    combination of the two.`,
  },
  {
    title: `Can you send messages to multiple people, via
    different methods?`,
    content: `Yes! We can send texts to one person, emails to
    another person, texts and emails to another person,
    or anything you prefer. Everything is completely up
    to you, and there is no limit on how many addresses
    receive messages.`,
  },
  {
    title: `Can I change the questions on my new customer
    intake?`,
    content: `Yes! Everything the virtual receptionists ask your
    callers is completely customizable, and you can make
    a change to these questions at any time.`,
  },
  {
    title: `How long does it take to get setup with Answering
    Real Estate?`,
    content: `Not long at all, in fact, we can usually have your
    service up and running in the same day, depending on
    your phone provider. It also depends on how readily
    available your phone account information is.`,
  },
];

const IndexPage = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="container">
            <div className="text-center pagehead">
              <h2>
                <strong>Integrates with your business</strong>
              </h2>
              <p className="para-15">
                Uniquely catering to Real Estate Agents and Property Managers.
              </p>
            </div>
            <div id="toggles" className="tabblock">
              <button className="tablinks span_3_of_12">
                Agencies/Brokers
              </button>
              <button className="tablinks span_3_of_12">
                Property Managers
              </button>
            </div>
            <div className="toglescontent_block">
              <div className="toglecontent">
                <div className="feature_list">
                  <div className="row">
                    <div className="col-sm-4">
                      <Link href="/real-estate-answering-service">
                        <div className="feature-box blue">
                          <img src={specialized} alt="" />
                          <h5>Real Estate Specialized</h5>
                          <p>
                            We specialize in answering for the Real Estate
                            industry. We never mess up your calls.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <a href="#">
                        <div className="feature-box green">
                          <img src={training} alt="" />
                          <h5>Intensive Operator Training</h5>
                          <p>
                            All of our operators are trained extensively before
                            they answer any live calls.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-4">
                      <Link href="/appointment-scheduling">
                        <div className="feature-box yellow">
                          <img src={calendar} alt="" />
                          <h5>Appointment Scheduling</h5>
                          <p>
                            You’re busy, but we know when you’re available.
                            We’ll schedule everything for you.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <a href="#">
                        <div className="feature-box yellow">
                          <img src={leadCapturing} alt="" />
                          <h5>Message Taking</h5>
                          <p>
                            Your messages need to be accurate every time. We
                            deliver with 100% accuracy.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-4">
                      <Link href="/intelligent-dispatching">
                        <div className="feature-box blue">
                          <img src={training} alt="" />
                          <h5>Intelligent Dispatching</h5>
                          <p>
                            We don’t just have one patch button. We know who to
                            call about each type of matter.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <Link href="/bilingual-answering-service">
                        <div className="feature-box green">
                          <img src={conversation} alt="" />
                          <h5>Bilingual Receptionists</h5>
                          <p>
                            Don’t lose a customer because of a language barrier.
                            We’re bilingual 24/7.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <Link href="/lead-capturing">
                        <div className="feature-box blue">
                          <img src={leadCapture2} alt="" />
                          <h5>Lead Capturing</h5>
                          <p>
                            We get the right information from potential
                            customers so you can obtain their business.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <a href="#">
                        <div className="feature-box green">
                          <img src={customization} alt="" />
                          <h5>100% Customizable</h5>
                          <p>
                            Everything we say, ask, transfer, or send is
                            customizable on the fly, 24 hours a day.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-4">
                      <Link href="/247-live-answering-service">
                        <div className="feature-box yellow">
                          <img src={afterhours} alt="" />
                          <h5>After Hours Answering</h5>
                          <p>
                            Customers are calling after hours. We answer so they
                            don’t call your competitors.
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <section className="cta_section inner">
                  <div className="text-center">
                    <h2>Start your 400 minute free trial!</h2>
                    <p className="para-15">
                      Try all of our premium services for free. All of our
                      services are completely
                      <br /> customizable. No contracts, credit cards or
                      commitments required.
                    </p>
                    <Link to="/signup" className="btn-lg mb-0">
                      Try Answering Real Estate Free
                    </Link>
                  </div>
                </section>

                <section className="faq_section inner pt-0">
                  <div className="faqsec">
                    <div className="center fadein">
                      <h2>Frequently asked questions</h2>
                    </div>
                    <div className="faq_list">
                      <div className="group row">
                        <div
                          className="col-sm-6"
                          onClick={() => setVisible(!visible)}
                        >
                          <p className="faq">
                            How do I sign up with Answering Real Estate?
                          </p>
                          {visible && (
                            <p className="faq_answer para-14">
                              Easy! Simply fill out a sign up form here:{" "}
                              <Link to="/signup">
                                <a>https://answeringrealestate.com/sign-up/</a>
                              </Link>{" "}
                              or give us a call at 631-450-1000, and one of our
                              customer success representatives will help you
                              setup your account.
                            </p>
                          )}
                        </div>
                        {FAQ_LIST.map((faq) => (
                          <Accordion {...faq} />
                        ))}
                      </div>
                    </div>
                    <hr className="span_10_of_12" />
                  </div>
                </section>

                <section className="contactsec">
                  <div className="text-center">
                    <h2>Have questions?</h2>
                    <div className="csinner">
                      <a
                        className="btn-lg btn-green"
                        id="callnow"
                        href="tel:6314501000"
                      >
                        Call us now
                      </a>
                    </div>
                  </div>
                  <a href="#" className="fixed-cta">
                    Make our team your team
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["SERVICES"]} />;
