import React, { useState } from 'react'

const NestedCustomAccordion = ({ title, faq, children }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div>
            <div className="tour_toggle" onClick={() => setIsActive(!isActive)}>
                <span className="ticon">
                    {!isActive && <span className="plus">+</span>}
                    {isActive && <span className="minus">–</span>}
                </span>
                {title ?? ''}
            </div>
            {isActive && <>
                {children}
            </>}
        </div>
    )
}

export default NestedCustomAccordion