import React from "react"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

import favicon from "../images/favicons/favicon.png"

export const SEO = ({ title, description, pathname, meta = {}, children }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={seo.title}
      titleTemplate={seo.title ? `%s | ${seo.title}` : null}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
      ].concat(meta)}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
    />
  )
}

export default SEO;