import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Real Estate Answering Service</h2>
                                        <p>
                                            Having a real estate answering service for your real estate business is a huge benefit. For one, you essentially are increasing the amount of staff you have by having the 24/7 support of a live answering service, and at a much lower cost than that of hiring a single employee in-house. But there are many other ways that a real estate answering service drastically improves your business.
                                        </p>
                                        <h3>A Real Estate Answering Service Won’t Mess Up Your Calls</h3>
                                        <p style={{ backgroundColor: '#F9FAFA', width: '100%' }}>
                                            A lot of other answering services can answer your calls and send you your messages, but they can’t interact with your callers as well as a specialized answering service like Answering Real Estate can. Other answering services’ receptionists have to answer calls for doctors, lawyers, plumbers, pet groomers, and have different protocols for each type of business. By the time one of your phone calls comes in, the likelihood that your agent is confused by all of the types of businesses he/she is answering for becomes much higher, and your business reputation could suffer as a result. Answering Real Estate knows how to speak to your callers, answer FAQs, schedule appointments, and transfer important calls to the correct person. None of your calls end up getting mishandled because each business that we answer for is in the same industry.

                                            More than that, because we don’t have to train our receptionists to answer for every industry, we are able to train them more intensively in answering for real estate than other services can. By the time our virtual receptionists finish their initial training, they are all experts in being polite, professional, and extremely accurate on the phones with your callers.
                                        </p>

                                        <h3>You Can Customize Everything For Your Unique Business</h3>
                                        <p>
                                            You run your business differently than other types of companies do. You are constantly on the run, meeting with clients, and driving all over to show your various properties. For this reason, you need different protocols for the different types of calls you receive, not just a cookie cutter approach. You can customize every single part of your service, down to how many rings you want to have on your phone before we even answer the phone here at Answering Real Estate!
                                        </p>

                                        <h4>Here are just some of the things you can customize with Answering Real Estate:</h4>
                                        <ul style={{listStyle: 'inside', lineHeight: '30px', paddingTop: '22px'}}>
                                            <li>Type of Call Forwarding</li>
                                            <li>Call Scripting and Handling</li>
                                            <li>Transferring and Dispatching</li>
                                            <li>New Customer Intake</li>
                                            <li>Appointment Scheduling</li>
                                        </ul>

                                        <p>There is no limit to what you can change with Answering Real Estate, as we are an extension of your business!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['REAL_ESTATE_ANSWERING_SERVICE']} />
)
