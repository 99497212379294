import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Intelligent Dispatching</h2>
                                        <p>
                                            Maybe there are a lot of people working in your office, maybe you have dedicated maintenance teams for specific problems, or maybe you have different agents on call for whatever reason throughout the day and night! No matter the reason, your answering service should be able to intelligently discern which type of matter requires the correct individual’s attention, and transfer the call to that person immediately.
                                        </p>
                                        <h3>Set Up Sophisticated Call Protocols</h3>
                                        <p style={{ backgroundColor: '#F9FAFA', width: '100%'}}>
                                        If a certain type of call is coming in, you might have multiple people that will be able to help. For example, if someone needs help with an HVAC problem, and you have multiple HVAC teams that you could potentially dispatch the problem to, you can list these options on your account, and we at Answering Real Estate can contact all of them, should the first maintenance company not answer. If the first company is unavailable or would take too long to get to the location in order to fix the problem, your virtual receptionist will decide to contact another maintenance company that can provide assistance even quicker.

You can also have multiple people on call to speak to potential new customers. If the person who usually speaks to new customers first isn’t available to have a call transferred into them, your virtual receptionist will try other members of your business if you’ve predetermined that is what should happen.

If the caller knows who he/she needs to speak with, your virtual receptionist at Answering Real Estate doesn’t only have one option to patch through to an “office number.” No, your virtual receptionist can contact the correct person that the caller has requested to speak with! We will announce the caller to you so that you can decide whether or not you would like to take the call, so you can determine your own availability!
                                         </p>
                                
                                        <h2>The Importance of Lead Capturing Accuracy</h2>
                                        <p>
                                        If someone in your office is going on vacation, you make a new hire, or you simply want to change up your protocol because of efficiency reasons, all you need to do is tell us who to contact about which matters, and we can instantly make changes for you. Your answering service should be able to adapt to your business instantly, and that’s why Answering Real Estate is your best choice.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['INTELLIGENT_DISPATCHING']} />
)
