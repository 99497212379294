import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import { Link } from "gatsby";
import Accordion from "../components/Accordion";
import CustomAccordion from "../components/CustomAccordion";
import NestedCustomAccordion from "../components/NestedCustomAccordion";
import SmallAccordion from "../components/SmallAccordion";

import SEO from "../components/Seo";
import { META_TAGS } from "../utils/metaTags";

const MAIN_FAQ_LIST = [
  {
    title: `Setting up your account`,
    faq: [
      {
        title: `Signing up`,
        content: `When you sign up with Answering Real Estate, you and
        your dedicated customer success expert will set up
        your account according to your specific needs.`,
      },
      {
        title: `What’s included`,
        content: `Your initial sign up automatically includes your 10
        day free trial, which requires no credit card,
        contract, or setup fee.`,
      },
      {
        title: `Same day setup`,
        content: `If your call forwarding is mostly compatible, we can
        have your service up and running the very same day!`,
      },
      {
        title: `Customizing your account`,
        content: `Once the basic information is relayed, you can begin
        to customize your call handling and everything else!`,
      },
      {
        title: `How to get setup`,
        content: `Call us at 631-450-1000 or fill out our *Sign Up*
        form.`,
      },
      {
        title: `How do I get my calls to you?`,
        content: `Using your preferred method of call forwarding, you
        will pass your phone calls to a unique telephone
        number we assign to you.`,
      },
    ],
  },
];
const FAQ_LIST = [
  {
    title: `Is Answering Real Estate bilingual?`,
    content: `Yes. Answering Real Estate is fully bilingual in
    English and Spanish, 24 hours a day.`,
  },
  {
    title: `Can Answering Real Estate try to patch calls to
    multiple people?`,
    content: `Yes. If one person is unavailable, you can
    predetermine the list of people you would like your
    virtual receptionists to try to reach before taking a
    message with the caller. You can set up different call
    protocols for different types of calls as well.`,
  },
  {
    title: `Can Answering Real Estate schedule appointments?`,
    content: `Yes. We collaborate to view your availability and
    schedule your appointments according to predetermined
    available times.`,
  },
  {
    title: `FAQ Missing`,
    content: `Yes, by simply sending an email to
    support@answeringrealestate.com or calling us at
    631-450-1000, you can let us know what you would like
    to change your custom greeting to. Everything about
    Answering Real Estate is customizable.`,
  },
  {
    title: `How do I get my messages?`,
    content: `Your messages can be sent to as many or as few people
    as you would like, in whatever form you request, via
    SMS Text and/or email.`,
  },
  {
    title: `Why does Answering Real Estate specialize in Real
    Estate?`,
    content: `Answering Real Estate recognizes the importance of
    call quality and timeliness when it comes to the Real
    Estate industry. A lot of these matters are personal
    and urgent, and deserve to be handled with the utmost
    care. Because of Answering Real Estate’s quality of
    call handling, it specializes in answering for the
    Real Estate industry.`,
  },
  {
    title: ` How does Answering Real Estate handle my calls?`,
    content: ` Answering Real Estate can follow any protocol that you
    request. If you would like all calls from new clients
    to be transferred, to have a message taken from all
    existing callers, and all maintenance problems to be
    dispatched to the appropriate maintenance team, we can
    accommodate. We can even send different types of
    messages to different employees.`,
  },
  {
    title: `Does it matter which state my business is in?`,
    content: ` No, Answering Real Estate answers for businesses in
    every single State in the United States.`,
  },
];

const CALL_FORWARDING = [
  {
    title: `Conditional Call Forwarding`,
    content: `Conditional call forwarding allows your phones to ring 3-4
    times before forwarding over to us at Answering Real
    Estate. This way, you can answer all the calls that you
    can when you are available, and never worry about missing
    a call when you can not get to the phone.`,
  },
  {
    title: `Time of Day Call Forwarding`,
    content: `Time of day call forwarding allows your calls to be
    automatically forwarded at certain times of the day. For
    example, if your office is open from 8 a.m. to 4 p.m. then
    your call forwarding will automatically activate at 4 p.m.
    and automatically deactivate for your opening time at 8
    a.m. without you having to remember to turn on and turn
    off call forwarding yourself.`,
  },
  {
    title: `Constant Call Forwarding`,
    content: `Constant call forwarding will allow you to forward your
    calls directly to us at Answering Real Estate, and will
    afford you two options: the first being that we can
    announce every caller by calling you and asking if you are
    available to take the call, before transferring calls to
    you. The second is that we answer every call and never try
    to transfer a call to you, but instead take a detailed
    message and let you call him/her back at your earliest
    convenience.`,
  },
];
const DISPATCHING = [
  {
    title: `Intelligent Dispatching`,
    content: `Customizing your dispatch protocols is simple, and takes a
    few minutes of time to confirm over the phone when setting
    up your account. You can have multiple people patched into
    different types of calls, or simply have one person to
    reach for each type of matter, whatever you prefer!`,
  },
  {
    title: `Message Taking`,
    content: `You can also customize the information we retrieve from
    your callers in your messages. If you want to start
    receiving email addresses, we will ask your clients for
    that information and send it to you in your messages! You
    get your messages lightning fast via SMS text, email, fax,
    or any combination of the three.`,
  },
  {
    title: `New Customer Intake`,
    content: `We can also perform full new customer intakes, using a
    script that you customize when originally signing up with
    us at Answering Real Estate, or have customized even more.`,
  },
];

// markup
const IndexPage = () => {
  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="container">
            <div className="text-center pagehead">
              <h2 className="mb-0">
                <strong>Everything you need to know</strong>
              </h2>
            </div>
            <div className="tourtoggle center">
              {MAIN_FAQ_LIST.map((faq) => (
                <CustomAccordion {...faq} />
              ))}
              <NestedCustomAccordion
                title={"Establishing your greeting and call protocol"}
              >
                <div className="tour_toggle_content">
                  <h5>Greeting</h5>
                  <p className="para-14">
                    One of the first things you will customize when setting up
                    your account is your customized greeting and the way your
                    calls are handled. This basically means that you will choose
                    how your virtual receptionists will answer the phones for
                    your office. For example, if your business is called,
                    “Excellent Real Estate,” your virtual receptionist might
                    answer:
                  </p>
                  <div className="tip-box tip-box-green">
                    “Good morning/afternoon, thank you for calling{" "}
                    <strong>Excellent Real Estate</strong>, how may I help you?”
                  </div>
                  <h5>Call Protocol</h5>
                  <p className="para-14">
                    You will also determine how your calls will be handled. If a
                    new customer is calling, we can patch them through to you or
                    schedule an appointment for them to come see you. If an
                    existing customer is calling, we can get all of the
                    information regarding that call so you can call him/her back
                    as soon as you can.
                  </p>
                </div>
              </NestedCustomAccordion>

              <NestedCustomAccordion
                title={"Choosing your dynamic call forwarding"}
              >
                <div className="call-forwarding tour_toggle_content">
                  <p className="para-14">
                    Customizing how you send us your calls is extremely
                    beneficial to your business. Utilizing dynamic call
                    forwarding, Answering Real Estate can answer all of your
                    calls in the following ways:
                  </p>
                  <h5>Types of call forwarding</h5>
                  <div>
                    {CALL_FORWARDING.map((faq) => (
                      <SmallAccordion {...faq} />
                    ))}
                  </div>
                  <p className="para-14">
                    Customizing your call forwarding is easy, so choose the type
                    of call forwarding that best fits your business!
                  </p>
                </div>
              </NestedCustomAccordion>

              <NestedCustomAccordion
                title={"Dispatching, message taking and customer intake"}
              >
                <div id="dispatching-message" className="tour_toggle_content">
                  <p className="para-14">
                    Because of our specialization in the real estate industry,
                    we at Answering Real Estate know who to patch into which
                    types of calls that come into your office.
                  </p>
                  <div>
                    {DISPATCHING.map((faq) => (
                      <SmallAccordion {...faq} />
                    ))}
                  </div>
                </div>
              </NestedCustomAccordion>
            </div>
          </div>

          <div className="tour_video_section">
            <div className="container">
              <div className="pagehead text-center">
                <h2 className="mb-0">Videos to help with the basics</h2>
              </div>
              <div className="row vidlist">
                <div className="col-sm-4">
                  <div className="videobox">
                    <h5>coming soon.</h5>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="videobox">
                    <iframe
                      width="100"
                      height="100"
                      src="https://www.youtube.com/embed/KRhdXOrQz7Q"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="videobox">
                    <h5>coming soon.</h5>
                  </div>
                </div>
              </div>
            </div>

            <section className="faq_section">
              <div className="container">
                <div className="faqsec">
                  <div className="center fadein">
                    <h2>Frequently asked questions</h2>
                  </div>
                  <div className="faq_list">
                    <div className="group row">
                      {FAQ_LIST.map((faq) => (
                        <Accordion {...faq} />
                      ))}
                    </div>
                  </div>
                  <hr className="span_10_of_12" />
                </div>
              </div>
            </section>

            <section className="cta_section pb-0">
              <div className="container">
                <div className="text-center">
                  <h2>Start your 400 minute free trial!</h2>
                  <p className="para-15">
                    Sign up and our dedicated support team will completely
                    handle your setup, call <br /> forwarding, and
                    customization. Get started now!
                  </p>
                  <Link to="/signup" className="btn-lg">
                    Try Answering Real Estate Free
                  </Link>
                  <p className="sub_txt fadein">
                    Have questions? Our team is here to help. Call us at
                    631.450.1000
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["TOUR"]} />;
