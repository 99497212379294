exports.components = {
  "component---src-pages-247-live-answering-service-js": () => import("./../../../src/pages/247-live-answering-service.js" /* webpackChunkName: "component---src-pages-247-live-answering-service-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-answering-service-pricing-js": () => import("./../../../src/pages/answering-service-pricing.js" /* webpackChunkName: "component---src-pages-answering-service-pricing-js" */),
  "component---src-pages-appointment-scheduling-js": () => import("./../../../src/pages/appointment-scheduling.js" /* webpackChunkName: "component---src-pages-appointment-scheduling-js" */),
  "component---src-pages-bilingual-answering-service-js": () => import("./../../../src/pages/bilingual-answering-service.js" /* webpackChunkName: "component---src-pages-bilingual-answering-service-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intelligent-dispatching-js": () => import("./../../../src/pages/intelligent-dispatching.js" /* webpackChunkName: "component---src-pages-intelligent-dispatching-js" */),
  "component---src-pages-lead-capturing-js": () => import("./../../../src/pages/lead-capturing.js" /* webpackChunkName: "component---src-pages-lead-capturing-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-answering-service-js": () => import("./../../../src/pages/quality-answering-service.js" /* webpackChunkName: "component---src-pages-quality-answering-service-js" */),
  "component---src-pages-real-estate-answering-service-js": () => import("./../../../src/pages/real-estate-answering-service.js" /* webpackChunkName: "component---src-pages-real-estate-answering-service-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

