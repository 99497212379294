import React, { useState } from 'react'

const SmallAccordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <p className="forwarding_toggle" onClick={() => setIsActive(!isActive)}>
                <span className="ficon">
                    {!isActive && <span className="plus-sm">+</span>}
                    {isActive && <span className="minus-sm">–</span>}
                </span>
                {title}
            </p>
            {isActive && <p className="forwarding-toggle-content para-14">
                {content}
            </p>}
        </>
    )
}

export default SmallAccordion