import * as React from "react";
import "../styles/styles.css";

import { graphql, Link } from "gatsby";

import MainLayout from "../components/MainLayout";
import SummaryBlog from "../components/SummaryBlog";

import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";
import Pagination from "../components/pagination";

// markup
export default function BlogIndex({ pageContext, data, location }) {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : `/${currentPage - 1}`;
  const nextPage = `/${currentPage + 1}`;

  const edges = data.allContentfulPost.edges;

  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="bread_heading_block">
            <div className="text-center pagehead">
              <h2>
                <strong>Blog</strong>
              </h2>
            </div>
            <div className="breadcrumb_block">
              <ol className="breadcrumbs text-small">
                <li>
                  <Link to="/" title="">
                    <span>Home</span>
                  </Link>
                </li>
              </ol>
            </div>
          </div>
          <div className="container">
            <div className="with_sidebar">
              <div className="row">
                <div className="col-sm-8 wsb_left">
                  <div className="content">
                    <div className="article-list">
                      {edges &&
                        edges.map(({ node }) => (
                          <SummaryBlog
                            title={node.title}
                            image={
                              node?.featuredImage?.gatsbyImageData.images
                                .fallback.src
                            }
                            description={node.description.description}
                            author={node.author}
                            publishedData={node.publishDate}
                            slug={node.slug}
                          />
                        ))}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      numPages={numPages}
                      isFirst={isFirst}
                      isLast={isLast}
                      nextPage={nextPage}
                      prevPage={prevPage}
                    />
                  </div>
                </div>

                <div className="col-sm-4 wsb_right">
                  <aside className="sidebar">
                    <ServicesSidebar />

                    <RecentPosts />
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          description {
            description
          }
          publishDate(formatString: "MMMM Do, YYYY")
          featuredImage {
            gatsbyImageData(formats: PNG)
          }
          slug
        }
      }
    }
  }
`;
