import * as React from "react";
import { Link } from "gatsby";

import "../../styles/styles.css";

const RelatedPosts = ({ posts }) => {
  return (
    <>
      {/* Related Posts */}
      <div className="related_posts">
        <h2>Related Posts</h2>
        <div className="related_posts_wrapper">
          {posts.map(({ node }) => {
            return (
              <div className="card" style={{ width: 500 }}>
                <img src={node.featuredImage.url} height={100} width={150} />
                <div className="title_content">
                  <Link to={`/blog/${node.slug}`}>
                    <span className="post_title">{node.title}</span>
                  </Link>
                  <span className="post_date">{node.publishDate}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RelatedPosts;
