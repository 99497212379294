import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Appointment Scheduling</h2>
                                        <p>
                                        As a real estate agent or property manager, being on the run is part of your job description, and scheduling an appointment takes time that you usually can’t afford because of meeting with customers, showing properties, and dealing with other issues associated with your properties. Most times, you can’t step away and take the time to field a phone call from a customer or potential customer, let alone find a mutual time where you are both available for an appointment! That is why it is so important for you to have a real estate answering service that offers appointment scheduling.
                                        </p>
                                        <h3>Your Answering Service’s Appointment Scheduling Should Integrate With Your Online Calendar</h3>
                                        <p style={{ backgroundColor: '#F9FAFA', width: '100%'}}>
                                        Hiring an answering service shouldn’t be an inconvenience for your business. In fact, it should be exactly the opposite: hiring an answering service is meant to help you gain more time by not having to constantly answer the phones and schedule appointments, so setting up your appointment scheduling services should be no different. Once you choose which answering service you want to hire, they should instantly be able to integrate with not only your business, but the calendaring software you use in your office. This way, nothing changes for you, and your quality of life as well as your clients’ drastically increases.
                                        </p>
                                
                                        <h3>Appointment Scheduling Services Are Effortless and Convenient</h3>
                                        <p>
                                        Not only do you have the convenience of not having to answer the phone thanks to an answering service, your appointments are scheduled without any effort, and are sent to your phone via SMS text, email, or any combination of the two, while also being added to your online calendar. Make sure your answering service sets reminders for you so that you don’t forget your appointments, now that everything is done for you!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['APPOINTMENT_SCHEDULING']} />
)
