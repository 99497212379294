import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>Bilingual Answering Service</h2>
                                        <p>
                                        When looking for ways to better serve your customers by always having a live person answer the phones for your real estate business, you might overlook the need for a bilingual answering service. However, even if a small portion of your clients are Spanish speaking, the potential fallout from your answering service’s inability to communicate with your customers in Spanish could be disastrous. If your customers don’t feel customer speaking to the agents who answer your phones, they will quickly decide to take their business elsewhere. A bilingual answering service can help you better cater to your customers, but also provides a plethora of other benefits as well.
                                        </p>
                                        <h3>A Bilingual Answering Service Can Help You Capture More Customers</h3>
                                        <p style={{ backgroundColor: '#F9FAFA', width: '100%'}}>
                                        When trying to grow your business, it is important that your answering service can handle the amount of incoming calls from your advertising, marketing, and word of mouth referrals. However, if you advertise your business to the surrounding Spanish speaking community, your answering service must provide bilingual receptionists! Your virtual receptionists should not only better cater to your existing customers, but should be equipped to speak to your potential new customers in the language that they are most comfortable speaking in. Without bilingual virtual receptionists, you may be doomed to lose potential customers when they call your company and can not easily communicate with the representative of your business.
                                        </p>
                                
                                        <h3>A Bilingual Answering Service is Necessary if You Already Have Spanish Speaking Customers
</h3>
                                        <p>
                                        When choosing your answering service, it is important that it can not only help your business’ growth efforts, but that it can help you provide better customer service to your existing customers. If a portion of your customer base speaks Spanish as the language they are most comfortable with then having a bilingual answering service is an absolute must, so that they can not only receive the help they need, but can have a pleasant experience while doing so.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['BILINGUAL_ANSWERING_SERVICE']} />
)

