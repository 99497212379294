import * as React from "react";
import "../../styles/styles.css";

const RecentPosts = () => {
    return (
        <div className="sideblock recentpost">
            <div className="sideittle">Recent Posts</div>
            <div className="sidemenu">
                <ul>
                    <li>
                        <a href="#">
                            After Hours Answering Service For Clients Calling
                            After Hours
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            In the News: Puerto Rico’s Housing Crisis After
                            Hurricane
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            Why Property Management Answering Services Are
                            Necessary
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            AWhy Real Estate Answering Services Need To
                            Provide High Quality Receptionists
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            In The News: A Drop In Mortgage Rates Increases
                            Mortgage Applications
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default RecentPosts;
