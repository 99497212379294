import * as React from "react";
import "../styles/styles.css";
import { Link } from "gatsby";

import Footer from "./Footer";
import Header from "./Header";

const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <a className="scrollup" href="javascript:void(0);"></a>
    </>
  );
};

export default MainLayout;
