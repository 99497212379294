export const META_TAGS = {
  HOME: {
    title: `24/7 Real Estate Answering Service | Try For Free!`,
    description: `Answering Real Estate Provides The Highest Quality 24/7 Answering Service For Realtors,
        Brokers, And Property Managers. Never Miss Another Call!`,
  },
  SERVICES: {
    title: `Our Services | Answering Real Estate`,
    description: `Lead Capturing, Message Taking Service, Appointment Scheduling, And more! All Available
        24/7 And Fully Bilingual. Try Answering Real Estate For Free Today!`,
  },
  TOUR: {
    title: `How Our Real Estate Answering Service Works | Try For Free!`,
    description: `Our Real Estate Receptionists Answer Calls As If They Were In Your Office! #1 Answering
        Service For Realtors, Brokers, And Property Managers. 24/7 Coverage!`,
  },
  ABOUT: {
    title: `About | #1 Real Estate Answering Service`,
    description: `Answering Real Estate Is An Extension Of Your Business. Capture Every New Lead - You’ll
        Never Miss A Call! Perfect For Realtors, Brokers, And Property Managers!`,
  },
  PRIVACY_POLICY: {
    title: `Privacy Policy - Answering Real State`,
    description: `Our privacy policy is meant to let users know how we use information. Please refer to this policy on our use of data.`,
  },
  PRICING: {
    title: `Real Estate Answering Service Pricing Plans | Try For Free!`,
    description: `Answering Real Estate Pricing Plans. Flat Rates Available. #1 Answering Service for Realtors,
        Brokers, And Property Managers. Sign Up For Our Free Trial Today!`,
  },
  ANSWERING_SERVICE_PRICING: {
    title: `#1 Real Estate Answering Service Pricing Plans`,
    description: `Answering Real Estate Is The Perfect Extension Of Your Business. Build A Custom Plan That
        Works For You. Sign Up For Our Free Trial Today!`,
  },
  SIGNUP: {
    title: `Sign Up Today! | Real Estate Answering Service Free Trial`,
    description: `Answering Real Estate Two Week Free Trial | No Credit Card Needed! Try The #1 Answering
        Service For Real Estate Agents, Brokers, And Property Managers Today!`,
  },
  QUALITY_ANSWERING_SERVICE: {
    title: `24/7 High-Quality Specialized Real Estate Answering Service`,
    description: `Our Receptionists Answer Exclusively For Real Estate Professionals! Make Sure Every Client Is
        Satisfied! Try The #1 Real Estate Answering Service Today!`,
  },
  LEAD_CAPTURING: {
    title: `Lead Capturing | Answering Real Estate | Try For Free!`,
    description: `Our Real Estate Receptionists Will Ensure New Opportunities For Your Business. Capitalize on
        Your Marketing by Never Missing Another Call!`,
  },
  INTELLIGENT_DISPATCHING: {
    title: `Intelligent Dispatching | Answering Real Estate`,
    description: `Optimize Your Office Workflow. Dispatch Cleaning Teams Or Maintenance To Properties, And
        More! Ensure Client Satisfaction By Never Missing Another Call!`,
  },
  APPOINTMENT_SCHEDULING: {
    title: `Appointment Scheduling | Answering Real Estate`,
    description: `#1 Real Estate Answering Service - We Keep Your Calendar Organized And Full Of
        Opportunities - Never Miss A Call - Integrate Directly With Your Calendar`,
  },
  BILINGUAL_ANSWERING_SERVICE: {
    title: `Bilingual Answering Service | Answering Real Estate`,
    description: `#1 Bilingual Answering Service For Real Estate Agents, Brokers, And Property Managers! Make
        Sure You Never Miss A Spanish-Speaking Client! Try For Free!`,
  },
  REAL_ESTATE_ANSWERING_SERVICE: {
    title: `#1 Real Estate Answering Service | Answering Real Estate`,
    description: `Never Miss Another Business Opportunity. Our Receptionists Schedule Appointments, Capture
        Leads, And Take Messages 24/7! Try For Free Today!`,
  },
  LIVE_ANSWERING_SERVICE: {
    title: `24/7 Live Answering Service | Answering Real Estate`,
    description: `Never Miss A Call, No Matter When It Comes In! #1 Answering Service For Real Estate Agents,
        Brokers, And Property Managers! Try Our 24/7 Service Today!`,
  },
};
