import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import RelatedPosts from "./RelatedPosts";

const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const target = node.data?.target;
      const url = `/blog/${target?.slug ? target.slug : ""}`;

      return <Link to={url}>{children}</Link>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      const isYoutube = uri.indexOf("youtube.com/embed/") !== -1;
      const isLocal = uri.indexOf("https://www.answeringrealestate.com") !== -1;

      if (isYoutube) {
        return (
          <figure>
            <iframe
              width="560"
              height="315"
              src={uri}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </figure>
        );
      } else if (isLocal) {
        const slug = uri.replace("https://www.answeringrealestate.com", "");

        return <Link to={slug}>{children}</Link>;
      } else {
        return (
          <a href={uri} target="_blank">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const target = node.data?.target;

      return <img alt={target?.title} fixed={target?.fixed} />;
    },
    // [BLOCKS.LIST_ITEM]: (node) => {
    //   const UnTaggedChildren = documentToReactComponents(node, {
    //     renderNode: {
    //       // eslint-disable-next-line no-shadow
    //       [BLOCKS.PARAGRAPH]: (node, children) => children,
    //       // eslint-disable-next-line no-shadow
    //       [BLOCKS.LIST_ITEM]: (node, children) => children,
    //     },
    //   });

    //   return <li>{UnTaggedChildren}</li>;
    // },
  },
};

export default function BlogPostTemplate({ data }) {
  const { title, publishDate, author, featuredImage, body, slug } =
    data.contentfulPost;
  const { name, gravatar } = author;
  const { url } = gravatar;

  const { url: featuredImage_url } = featuredImage;

  const relatedPosts = data.relatedPosts.edges;

  return (
    <MainLayout>
      <div className="inner_main_wrapper article_main">
        <div className="inner_container">
          {/*Article Feature Image with Headline */}
          <div className="article_header">
            <div className="article_header_wrapper">
              <div className="article_heading_container">
                <div className="article_info_wrapper">
                  <span className="article_category">
                    After Hours Answering Service
                  </span>
                  <span className="article_estimated_read">2 Minute Read</span>
                </div>
                <h1 className="article_title">{title}</h1>
                <div className="article_author_wrapper">
                  <div className="author_image">
                    <img
                      className="gravatar-image"
                      alt={gravatar.title}
                      src={url}
                    />
                  </div>
                  <div className="author_info">
                    <span className="author_name">{name}</span>
                    <span className="post_date">{publishDate}</span>
                  </div>
                </div>
              </div>
              <div className="article_feature_image_container">
                <img src={featuredImage_url} height="330" />
              </div>
            </div>
          </div>
          {/* Start Divider */}
          <hr className="horizontal_line" />

          {/* Article Content */}
          <div className="container">
            <div className="article_content">
              <article className="blog_html">
                {body && renderRichText(body, options)}
              </article>
            </div>
            <hr className="horizontal_line" />
            {/* End Divider */}

            {/* Related Posts */}
            <RelatedPosts posts={relatedPosts} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export const Head = ({ data }) => {
  const { title, description, snippetTitle } = data.contentfulPost;
  const hasSnippetTitle = snippetTitle !== null;
  return (
    <Helmet>
      <title>{hasSnippetTitle ? snippetTitle : title}</title>
      <meta name="description" content={description.description} />
    </Helmet>
  );
};

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      description {
        description
      }
      snippetTitle
      slug
      author {
        name
        gravatar {
          url
          title
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      featuredImage {
        url
      }
      body {
        raw
      }
    }
    relatedPosts: allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
      filter: { slug: { ne: $slug } }
    ) {
      edges {
        node {
          title
          contentful_id
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          featuredImage {
            url
          }
        }
      }
    }
  }
`;
