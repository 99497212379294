import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";

import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
    return (
        <MainLayout>
            <div className="inner_main_wrapper">
                <div className="inner_container">
                    <div className="container">
                        <div className="with_sidebar">
                            <div className="row">
                                <div className="col-sm-8 wsb_left">
                                    <div className="content">
                                        <h2>24/7 Live Answering Service</h2>
                                        <p>
                                            Answering Real Estate doesn’t just offer answering services during the day and then give you a voicemail box for your customers to leave messages on at night. We burn the midnight oil 365 days a year as a 24/7 live answering service to give the best possible service not only to you, but to your customers! Sometimes emergencies happen, and everyone likes to know that they have someone to turn to should something go wrong. Having a 24/7 live answering service can help your tenants, homeowners, and potential clients with the following by answering calls 24/7 and helping dispatch appropriate help for you:
                                        </p>
                                        <ul>
                                            <li>Plumbing issues</li>
                                            <li>Heating and cooling problems</li>
                                            <li>Physical damage needing repair</li>
                                            <li>Electrical outages</li>
                                        </ul>
                                        <h3>Why a 24/7 Live Answering Service is Necessary for Real Estate?</h3>
                                        <p >
                                            Real Estate Agents don’t stop working when the clock strikes 5. They’re still out showing properties, apartments, homes, and other things – but usually their receptionists have retired for the night! If your customers know that you’re still out and about, they’re of course going to try and reach you by phone with any requests or needs. That’s why it’s extremely important to have a 24/7 live answering service to answer the phones – so that your existing customers don’t perceive the indifference that a voicemail exerts. Every single one of your customers is afforded the opportunity to speak to a live representative of your business and can rest assured that you will receive their message.
                                        </p>

                                        <h3>Hiring a Real Estate Agent Happens During Off Hours</h3>
                                        <p>
                                            A lot of people don’t have time to reach out to you for help during the day because they’re working! They’ll most likely try to call you before they begin work or after they’re finished, meaning that unless you have a receptionist in your office over 16 hours a day, you’re likely missing calls from potential customers who need your services! According to a study done by Forbes magazine, 72% of callers hang up on voicemail and look for help elsewhere, so ensuring you have a live representative of your business answering the phone is paramount to your success.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-sm-4 wsb_right">
                                    <aside className="sidebar">
                                       <ServicesSidebar />
                                        <RecentPosts />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default IndexPage;

export const Head = () => (
    <SEO {...META_TAGS['LIVE_ANSWERING_SERVICE']} />
)
