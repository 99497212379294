import * as React from "react";
import "../../styles/styles.css";

import { Link } from "gatsby";

const ServicesSidebar = () => {
    return (
        <div className="sideblock">
            <div className="sideittle">Services</div>
            <div className="sidemenu">
                <ul>
                    <li>
                        <Link to="/quality-answering-service">Quality Answering Service</Link>
                    </li>
                    <li>
                        <Link to="/lead-capturing">Lead Capturing</Link>
                    </li>
                    <li>
                        <Link to="/intelligent-dispatching">Intelligent Dispatching</Link>
                    </li>
                    <li>
                        <Link to="/appointment-scheduling">Appointment Scheduling</Link>
                    </li>
                    <li>
                        <Link to="/bilingual-answering-service">Bilingual Answering Service</Link>
                    </li>
                    <li>
                        <Link to="/real-estate-answering-service">Real Estate Answering Service</Link>
                    </li>
                    <li>
                        <Link to="/247-live-answering-service">24/7 Live Answering Service</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ServicesSidebar;
