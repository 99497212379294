import * as React from "react";
import "../styles/styles.css";

import { Link } from "gatsby";

import logoImg from "../images/logo.png";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
  const [isToggled, setToggle] = React.useState(false);
  return (
    <header className="header">
      <div className="header_top">
        <div className="container">
          <div className="topleft">
            <div className="logo">
              <a href="/">
                <img src={logoImg} alt="" title="" width="329" height="52" />
              </a>
            </div>
          </div>
          <div className="ht_right">
            <div className="nav_block">
              <div className="nav-menu">
                <ul className="clearfix">
                  <li>
                    <Link to="/services" className="header_navbar">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/tour" className="header_navbar">
                      Tour
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="header_navbar">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="header_navbar">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="header_navbar">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup" className="header_navbar">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <span className="resnav" onClick={() => setToggle(!isToggled)}>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <Offcanvas
              show={isToggled}
              onHide={() => setToggle(!isToggled)}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="mobileMenu">
                  <ul>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/tour">Tour</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  </ul>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
