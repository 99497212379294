import * as React from "react";
import "../styles/styles.css";
import { Link } from "gatsby";

const SummaryBlog = (props) => {
  const { image, title, description, publishedData, author, slug } = props;

  return (
    <article className="post">
      <div className="blog-media">
        <a href={`/blog/${slug}`}>
          <img
            src={image}
            alt=""
            width="178"
            height="118"
            style={{ borderRadius: 7 }}
          />
          <i></i>
        </a>
      </div>
      <div className="blog-content">
        <h3 className="entry-title">
          <Link to={`/blog/${slug}`} title={title}>
            {title}
          </Link>
        </h3>
        <div className="entry-meta">
          {/* <span className="category-link">
                        <span href="#">after hours answering service</span>,
                        <a href="#">
                            Property Management Answering Services
                        </a>
                        ,<a href="#">Real Estate Answering Services</a>
                    </span> */}
          {/* <div className="avdateblk">
            <a className="author vcard" href="#" title="">
              By <span className="fn">{author.name}</span>
            </a>
            <a href="#" title="" className="data-link">
              <div className="entry-date">{publishedData}</div>
            </a>
          </div> */}
        </div>
        <p>{description}</p>
        <Link to={`/blog/${slug}`} className="details more-link">
          Details
        </Link>
      </div>
    </article>
  );
};

export default SummaryBlog;
