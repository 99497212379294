import * as React from "react";
import "../styles/styles.css";

import MainLayout from "../components/MainLayout";
import ServicesSidebar from "../components/Sidebar/ServicesSidebar";
import RecentPosts from "../components/Sidebar/RecentPosts";
import SEO from "../components/Seo"
import { META_TAGS } from "../utils/metaTags";

const IndexPage = () => {
  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="container">
            <div className="with_sidebar">
              <div className="row">
                <div className="col-sm-8 wsb_left">
                  <div className="content">
                    <h2>About us</h2>
                    <p>
                      Answering Real Estate is the undisputed exclusive
                      answering service for Real Estate Agents, Brokers,
                      Property Managers, and more. We serve as more than just
                      your answering service; we’re an extension of your
                      business. We train each and every one of our virtual
                      receptionists not only to answer for the real estate
                      industry, but for your unique business. Every business is
                      different, and that’s why taking the time to learn about
                      your specific needs is paramount to the success of not
                      only your business moving forward, but your partnership
                      with us at Answering Real Estate.
                    </p>
                    <h3>The Most Trustworthy Real Estate Answering Service</h3>
                    <p>
                      Answering Real Estate is also the most trusted answering
                      service for real estate by professionals because of its
                      amazing features and policies: every premium feature is
                      included with every account, there are no setup fees, no
                      cancellation fees, and no contracts. You can leave at any
                      time as there are no boundaries for you to cancel service.
                      That is because we are so confident that you will love our
                      real estate answering service, and will be with us until
                      you retire!
                    </p>
                    <h3>Best In Class Customer Service</h3>
                    <p>
                      Answering Real Estate’s 24 hour customer support means
                      that you never have to wait until the next business day to
                      make changes to your account or fix a problem that you’re
                      having! Call us, email us, or chat with us, we’re
                      available at all times to keep your account and your
                      business running as smoothly as possible. Our 24 hour
                      support is included with every account, so don’t worry
                      that live support will cost you extra. We’re here to help,
                      no matter what time of day you need us.
                    </p>
                    <h3>Our Mission:</h3>
                    <p>
                      We want every person who calls into your business to have
                      an excellent experience, which means the agent on the
                      phone has to be friendly, polite, and professional, but
                      also has to deliver more than just an efficient answer to
                      their question. We want your callers to feel like they are
                      calling someone who is having an amazing day, and that’s
                      why we train all of our receptionists to answer this way.
                      Everyone who calls into your office will be greeted in a
                      positive manner, which will help them have a positive
                      experience with your business!
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 wsb_right">
                  <aside className="sidebar">
                   <ServicesSidebar />
                   <RecentPosts />
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO {...META_TAGS['ABOUT']}/>
)
