import styled from "styled-components";
import { Link } from "gatsby";

export const PaginationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 0 80px;
  padding: 0;
`;

export const PaginationLink = styled(Link)`
  background-color: ${(props) => (props.$isActive ? "#3197D6" : "#fff")};
  border-radius: 100px;
  border: ${(props) =>
    props.$isActive ? `1px solid ${"#3197D6"}` : "1px solid #ddd"};
  color: ${(props) => (props.$isActive ? "#fff" : "#222")};
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  margin: 2px;
  outline-offset: 3px;
  padding: 1em 1.25em;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #3197d6;
    color: #fff;
  }
`;
